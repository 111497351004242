<!-- -----------------------Starting View---------------------- -->

<div *ngIf="loading" class="load_screen fadeinout">
  <h2 class="load_animation" style="font-family: nalika-sigature">
    Eko Gold <br />
    Villas
  </h2>
</div>

<!-- -----------------------Starting View---------------------- -->

<section
  class="lux_banner"
  [ngClass]="{ transfrom: isChanged }"
  [ngStyle]="{
    'background-image':
      'url(' +
      imagePropertySrc +
      backgroundImageUrl?.property_media[0].media +
      ')'
  }"
  [routerLink]="
    '/property/location/villas-in-' +
    backgroundImageUrl?.locname +
    '/' +
    backgroundImageUrl?.permalink
  "
>
  <div class="row B_topper">
    <div class="col-sm-5">
      <div class="villa_banner_info">
        <div [ngClass]="{ 'animated-text': isChanged }" class="banner_div">
          <h3 class="villa_name">
            <!-- <span> EKOSTAY - </span> -->
            <span>{{
              cleanPropertyName(backgroundImageUrl?.property_name)
            }}</span>
          </h3>
          <h4 class="villa_locad">
            <i aria-hidden="true" class="fa fa-map-marker"></i>
            <span style="font-family: Nanum Myeongjo, serif">
              {{ backgroundImageUrl?.locname }}
            </span>
          </h4>

          <div class="villa_info_list">
            <div class="item">
              <i aria-hidden="true" class="fa fa-users"></i>
              {{ backgroundImageUrl?.member }}
            </div>
            <div class="item">
              <i aria-hidden="true" class="fa fa-bed"></i>
              {{ backgroundImageUrl?.bed }}
            </div>
            <div class="item">
              <i aria-hidden="true" class="fa fa-bath"></i>
              {{ backgroundImageUrl?.bathrooms }}
            </div>
          </div>
        </div>

        <div class="villa_opto">
          <div class="view_villa" (click)="openPropertyVideo();$event.stopPropagation()" *ngIf="backgroundImageUrl?.video_prismic_url">
            <a href="javascript:void(0);">Property Video</a>
          </div>

          <div class="view_villa">
            <a
              href="javascript:void(0);"
              (click)="showVilla(); $event.stopPropagation()"
              >View All villas</a
            >
          </div>
        </div>
      </div>
    </div>

    <div class="col-sm-6 lux_villa_carousel" (click)="$event.stopPropagation()">
      <owl-carousel-o [options]="luxevillas" class="" #owlCar>
        <ng-container
          *ngFor="let item of aboutData; let first = first; index as i"
        >
          <ng-container *ngIf="!first">
            <ng-template carouselSlide [id]="item.id">
              <div class="slide" (click)="maximize(item); owlCar.next()">
                <img
                  class="villa-image"
                  [src]="imagePropertySrc + item.property_media[0].media"
                  alt=""
                  loading="lazy"
                />
              </div>
            </ng-template>
          </ng-container>
          <div class="slide" *ngIf="first">
            <img
              src="../../../../assets/img/Bali.jpg"
              alt=""
              class="villa-image"
              loading="lazy"
            />
          </div>
        </ng-container>
      </owl-carousel-o>
    </div>
  </div>
</section>

<section class="whats_gold">
  <div class="container">
    <div class="heading">
      <h2 class="title" data-aos="fade-up" data-aos-duration="1000">
        WHAT IS A GOLD CATEGORY PROPERTY?
      </h2>
      <p class="sub_title" data-aos="fade-up" data-aos-duration="1000">
        EKO GOLD is our cherry-picked compilation of some of the best villas and
        homestays across India. A step towards expanding our luxury footprint
        while giving you elite treatment & services, is what we strive to offer!
      </p>
    </div>

    <!-- for deskTop view  -->
    <div class="row" *ngIf="isMobile">
      <div class="col-sm-4" data-aos="fade-up" data-aos-duration="1000">
        <div class="features_box">
          <img class="icon" src="../../../assets/img/tasteful-interior.png" loading="lazy"/>
          <div class="title">Tasteful<br />Interiors</div>
        </div>
      </div>

      <div class="col-sm-4" data-aos="fade-up" data-aos-duration="1000">
        <div class="features_box">
          <img class="icon" src="../../../assets/img/aesthetic-finishing.png" loading="lazy"/>
          <div class="title">Aesthetic<br />Furnishing</div>
        </div>
      </div>

      <div class="col-sm-4" data-aos="fade-up" data-aos-duration="1000">
        <div class="features_box">
          <img class="icon" src="../../../assets/img/chic-outdoor.png" loading="lazy"/>
          <div class="title">Chic Outdoor<br />Spaces</div>
        </div>
      </div>
    </div>

    <!-- for mobile view  -->
    <div class="row mobile_view_cards" *ngIf="!isMobile">
      <div class="col-sm-2" data-aos="fade-up" data-aos-duration="1000">
        <div class="features_box">
          <img class="icon" src="../../../assets/img/tasteful-interior.png" loading="lazy"/>
          <div class="title">Tasteful<br />Interiors</div>
        </div>
      </div>

      <div class="col-sm-2" data-aos="fade-up" data-aos-duration="1000">
        <div class="features_box">
          <img class="icon" src="../../../assets/img/aesthetic-finishing.png" loading="lazy"/>
          <div class="title">Aesthetic<br />Furnishing</div>
        </div>
      </div>

      <div class="col-sm-2" data-aos="fade-up" data-aos-duration="1000">
        <div class="features_box">
          <img class="icon" src="../../../assets/img/chic-outdoor.png" loading="lazy"/>
          <div class="title">Chic Outdoor<br />Spaces</div>
        </div>
      </div>
    </div>
  </div>
</section>

<section *ngIf="vila_filter" class="villa_filter">
  <div
    class="location__selected"
    *ngIf="!isMobile && false"
    data-aos="fade-up"
    data-aos-duration="1000"
    (click)="openFilterDialog()"
  >
    <!-- <span class="palce" *ngIf="!selectedLocation">Location</span>
  <span class="palce" *ngIf="selectedLocation">
    {{ selectedLocation }}
  </span> -->
    <span *ngIf="checkin && checkout">
      {{ checkin | date : "dd/MM/YYYY" }}&nbsp; - &nbsp;{{
        checkout | date : "dd/MM/YYYY"
      }}
    </span>
    <span *ngIf="selectedGuest">{{ selectedGuest }} guest</span>
    <span *ngIf="!checkin && !checkout">Check In - Check Out</span>
    <span *ngIf="!selectedGuest">Guest</span>
  </div>

  <div
    *ngIf="isMobile"
    class="container filter-wrapper"
    data-aos="fade-up"
    data-aos-duration="1000"
  >
    <div class="row">
      <!-- <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12 filter-feild">
        <div class="form-group">
          <mat-select
            placeholder="Location"
            [(ngModel)]="selectedLocation"
            (selectionChange)="filterProperties($event)"
          >
            <mat-option
              *ngFor="let option of locationDatas"
              [value]="option.name"
              (onSelectionChange)="onUnSelectedLocation($event, option.name)"
            >
              <span
                [ngStyle]="{
                  color: option.name === selectedLocation ? 'gray' : 'white'
                }"
                >{{ option.name }}
              </span>
            </mat-option>
          </mat-select>
        </div>
      </div> -->

       <!-- <div class="col-lg-4 col-md-4 col-sm-6 col-xs-6 filter-feild"> 
        <mat-form-field appearance="fill">
          <mat-date-range-input
            [rangePicker]="picker"
            [min]="minDate"
            class="form-control form-padd"
          >
            <input
              class="placeholder"
              matStartDate
              #fromInput
              (dateInput)="checkIn(checkin)"
              placeholder="Check in"
              [(ngModel)]="checkin"
              readonly
            />

            <input
              class="placeholder1"
              matEndDate
              #toInput
              placeholder="Check out"
              [(ngModel)]="checkout"
              (dateInput)="checkOut(checkout)"
              (dateChange)="filterProperties()"
              id="datepicker"
              readonly
            />
          </mat-date-range-input>
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
      </div>  -->

       <!-- <div class="col-lg-4 col-md-4 col-sm-6 col-xs-6 filter-feild">
        <button
          mat-stroked-button
          class="filter-button my class"
          (click)="openDialog()"
        >
          <span
            [hidden]="selectedTopFilterCount == 0"
            matBadge="{{ selectedTopFilterCount }} "
            matBadgePosition="after"
          >
            FILTERS
          </span>
          <span *ngIf="selectedTopFilterCount == 0">FILTERS</span>
        </button>
      </div>  -->

     <!-- <div class="col-lg-4 col-md-4 col-sm-6 col-xs-6 filter-feild">
        <div class="form-group">
          <mat-select
            placeholder="Guest"
            [(ngModel)]="selectedGuest"
            (selectionChange)="filterProperties()"
          >
            <mat-option *ngFor="let option of guestoptions" [value]="option">
              {{ option }}
            </mat-option>
          </mat-select>
        </div>
      </div> -->

      <!-- <div class="col-lg-4 col-md-4 col-sm-6 col-xs-6 filter-feild">
        <div class="form-group">
          <mat-select
            placeholder="Sort By"
            (selectionChange)="filterProperties()"
            [(ngModel)]="selectedSort"
          >
            <mat-option
              *ngFor="let sortOption of sortBy"
              [value]="sortOption.id"
            >
              {{ sortOption.name }}
            </mat-option>
          </mat-select>
        </div>
      </div> -->
    </div>
  </div>

  <div class="row">
    <div class="col-md-12 chip-filter horizontal dragscroll">
      <div class="min-filter-tab">
        <mat-chip-list>
          <mat-chip (click)="selectAll()">All</mat-chip>
          <ng-container *ngFor="let option of featuredLocation">
            <mat-chip
              *ngIf="option"
              [selected]="option === selectedLocation"
              (click)="selectLocation(option)"
            >
              {{ option }}
              <mat-icon
                matChipRemove
                *ngIf="option === selectedLocation"
                (click)="clearSelection()"
                >cancel</mat-icon
              >
            </mat-chip>
          </ng-container>
        </mat-chip-list>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="Properties property-list">
      <div class="row">
        <div *ngFor="let property1 of aboutData; let i = index">
          <app-property-card
            [property1]="property1"
            [final_price_pi]="property1.final_price_pi"
            [customOptions]="customOptions"
            [location]="location"
            [numberOfNightss]="numberOfNights"
            [customOptionsAminity]="customOptionsAminity"
            [imageSrc]="imagePropertySrc"
          ></app-property-card>
          <!-- <ng-container>
            <div
              class="col-md-6 bsp_padding-0"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <div
                class="bsp_bbb_item"
                [ngClass]="{
                  'gold-bg': property1.gold == 'on',
                  'luxe-bg': property1.luxe == 'on'
                }"
              >
                <img
                  *ngIf="property1.gold == 'on'"
                  class="ribbon"
                  src="assets/img/black-ribbon.png"
                />
                <div *ngIf="property1.gold == 'on'" class="ribbon-word">
                  Eko Gold
                </div>
                <img
                  *ngIf="property1.luxe == 'on'"
                  class="ribbon"
                  src="assets/img/black-ribbon.png"
                />
                <div
                  *ngIf="property1.luxe == 'on'"
                  class="ribbon-word eko_luxe"
                >
                  Eko Luxe
                </div>
                <div class="image-box">
                  <div class="badges">
                    <span
                      class="badge alert-primary"
                      *ngIf="property1.family_friendly == 'on'"
                      >Family Friendly</span
                    >
                    <span
                      class="badge alert-success"
                      *ngIf="property1.offbeat_villas == 'on'"
                      >Offbeat Villa</span
                    >
                    <span
                      class="badge alert-success"
                      *ngIf="property1.newly_villas == 'on'"
                      >Newly Villa</span
                    >
                    <span
                      class="badge alert-success"
                      *ngIf="property1.all_white == 'on'"
                      >All White</span
                    >
                    <span
                      class="badge alert-success"
                      *ngIf="property1.budget_friendly == 'on'"
                      >Budget Friendly</span
                    >
                    <span
                      class="badge alert-success"
                      *ngIf="property1.outdoorsy == 'on'"
                      >Outdoorsy</span
                    >
                  </div>


                  <owl-carousel-o
                    [options]="customOptions"
                    *ngIf="property1.property_media.length != 0"
                  >
                    <ng-container
                      *ngFor="
                        let pmedia of property1.property_media
                          | SortBy : 'sort';
                        index as i
                      "
                    >
                      <ng-template carouselSlide [id]="pmedia.id">
                        <div class="slide">
                          <a
                            href="property/location/villas-in-{{
                              property1.locname
                            }}/{{ property1.permalink }}"
                            class="bsp_a"
                          >
                            <img
                              [src]="imagePropertySrc + pmedia.media"
                              width="300"
                              height="300px"
                              alt=""
                            />
                          </a>
                          <div *ngIf="i === 4" class="view-more-slide">
                            <a
                              href="property/location/{{
                                property1.locpermalink
                              }}/{{ property1.permalink }}"
                              target="_blank"
                            >
                              <div class="view-more-button-slide">
                                View More
                              </div>
                            </a>
                          </div>
                        </div>
                      </ng-template>
                    </ng-container>
                  </owl-carousel-o>

                  <div class="slide">
                    <a
                      href="property/location/{{ property1.locpermalink }}/{{
                        property1.permalink
                      }}"
                      loading="lazy"
                      class="bsp_a"
                      target="_blank"
                    >
                      <img
                        ngSrc="{{ property1.image }}"
                        width="358"
                        height="200"
                        alt="Villa in"
                        priority
                      />
                    </a>
                  </div>

                  <div class="detail-desc">
                    <h5 *ngIf="property1.status == 0" class="bsp_card-title">
                      <span>Currently Inactive</span>
                    </h5>

                    <hr />
                    <div class="text-left">
                      <ul class="roominn">
                        <li class="third-desc">
                          <i aria-hidden="true" class="fa fa-users"></i>
                          <span class="icon-count">{{ property1.member }}</span>
                        </li>

                        <li class="first-desc">
                          <i aria-hidden="true" class="fa fa-bed"></i
                          ><span class="icon-count">{{ property1.bed }}</span>
                        </li>
                        <li class="second-desc">
                          <i class="fa fa-bath" aria-hidden="true"></i
                          ><span class="icon-count">{{
                            property1.bathrooms
                          }}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <a
                  href="property/location/{{ property1.locpermalink }}/{{
                    property1.permalink
                  }}"
                  class="bsp_a"
                >
                </a>
              </div>
              <div class="villa_info">
                <div class="villa_name">
                  <p class="Text-title">{{ property1.property_name }}</p>
                  <p class="Text-subtitle">{{ property1.locname }}</p>
                </div>
                <div class="villa_price">
                  ₹

                  {{
                    numberOfNights
                      ? (property1.inputname_monday *
                          (1 + 0.26) *
                          numberOfNights | number : "2.")
                      : (property1.inputname_monday * (1 + 0.26)
                        | number : "2.")
                  }}
                  <span class="Onwards">Night Onwards</span>
                </div>
              </div>
            </div>
          </ng-container> -->
        </div>
      </div>
    </div>
  </div>
</section>
