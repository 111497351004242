import { Injectable } from '@angular/core';

import {Router, ActivatedRoute, Params} from '@angular/router';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import CryptoJS from 'crypto-js';


@Injectable()
export class detailservice {
  isSessionVaild = true;
  constructor(private http: HttpClient,private activatedRoute: ActivatedRoute, public router: Router) {  }
  public propertyDataBehaviour:BehaviorSubject<any> = new BehaviorSubject({});
  public couponCodeBehaviour:BehaviorSubject<any> = new BehaviorSubject({});
  public checkinBehaviour:BehaviorSubject<any> = new BehaviorSubject({});
  public checkoutBehaviour:BehaviorSubject<any> = new BehaviorSubject({});
  public hostContacrNoBehaviour:BehaviorSubject<any> = new BehaviorSubject({});
  getDetails(id:any) {
    return this.http.get('https://'+environment.domain+'/public/api/property-detail/'+id,{
      headers : {
          'Content-Type' : 'application/json'
      }
    });
  }
  getDetailsBooking(id:any, checkin?:any, checkout?:any):Observable<any>{
    return this.http.get<any>('https://'+environment.domain+'/public/api/property-detail-booking/'+`${id}?checkin=${checkin}&checkout=${checkout}`,{
      headers : {
          'Content-Type' : 'application/json'
      }
    });
  }

  getDetailss(id:any,checkin:any,checkout:any,location:any,guest:any) {       
   
    return this.http.get('http://husainkhatumdi.com/admin/public/api/property-details/'+id +'?location='+location +'&checkin='+checkin
    +'&checkout='+checkout+'&guest='+guest,{
      headers : {
          'Content-Type' : 'application/json'
      }
    });
  }

  getSpecial(id:any) {       
   
    return this.http.get('https://'+environment.domain+'/public/api/propertiesprice/'+id,{
      headers : {
          'Content-Type' : 'application/json'
      }
    });    
  }


  getCusreview(id:any) {       
   
    return this.http.get('https://'+environment.domain+'/public/api/customerreview/'+id,{
      headers : {
          'Content-Type' : 'application/json'
      }
    });
  }

  getAbouts(location:any) {
    if(location){
      location = location;
    }else{
      location = '';
    }
    var sedreq = 'https://'+environment.domain+'/public/api/propertiestest?location='+location;

    return this.http.get(sedreq,{      
      headers : {
         'Content-Type' : 'application/json'       
      }      
         
    });
  }

// ------------------new code----------------------------//

  getPropertyOfferDetails(id:any,checkin:any,checkout:any,location:any,guest:any,pet:any,childrenUnderSix:any) {       
  
    return this.http.get('https://'+environment.domain+'/public/api/property-details/'+id +'?location='+location +'&checkin='+checkin
    +'&checkout='+checkout+'&guest='+guest+'&pet='+pet+'&under_6='+childrenUnderSix,{
      headers : {
          'Content-Type' : 'application/json'
      }
    });
  }

  getCouponOffer(id:any){
    return this.http.get('https://'+environment.domain+'/public/api/exist-coupon?property_id='+id)
  }

  trackPurchase(email: string, phoneNumber: string, price: number) {
    const API_URL = 'https://graph.facebook.com/v12.0/937622027812421/events';
    const ACCESS_TOKEN = 'EAAPm4Km1oMMBO17qK9ZBCO3kaLjkzhMZAXR0Hf3dLGdeBRQ86DCiIOYUd1fPlgYZBOQGx1F3OPaNHJFv0MZBKpmwD6W6XD0AWHNgjAI7GoaQZAltBXoJjB41caZAyKkEDKFO3ZBxF0s4f7Gygkd3sHEZCby8usXrMV35e6bTw0xNmGZABw7xXVvzjdOF7tlxNi1NYWwZDZD';

    const hashedEmail = CryptoJS.SHA256(email).toString();
    const hashedPhoneNumber = CryptoJS.SHA256(phoneNumber).toString();

    const eventPayload = {
      data: [
        {
          event_name: 'Purchase',
          event_time: Math.floor(Date.now() / 1000),
          event_source_url: 'ekostay.com' + this.router.url, // Update with your website URL
          action_source: 'website', // Indicate event source as website
          user_data: {
            em: [hashedEmail],
            ph: [hashedPhoneNumber]
          },
          custom_data: {
            currency: 'INR',
            value: price
          },
          event_id: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15) // Generating a unique event_id as per documentation
        }
      ]
    };

    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json');

    const params = new HttpParams()
      .set('access_token', ACCESS_TOKEN);

    return this.http.post(API_URL, eventPayload, { params, headers });
  }
  
}
