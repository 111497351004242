import { Component, Inject, OnInit, PLATFORM_ID } from "@angular/core";
import { aboutservice } from "../../services/aboutservice";
import { locationservice } from "../../services/locationservice";
import { environment } from "../../../environments/environment";
import { Router, ActivatedRoute } from "@angular/router";
import { Title, Meta } from "@angular/platform-browser";
import { OwlOptions } from "ngx-owl-carousel-o";
import { SiteDataHandlerService } from "src/app/services/site-data-handler.service";
import * as AOS from "aos";
import moment from "moment";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { AboutDialogComponent } from "../../modules/about/about/dialog.component";
import { GoldLuxeDialogComponent } from "../gold/goldLuxe.component";
import { isPlatformBrowser } from "@angular/common";
import { FilterDialogComponent } from "../filter-dialog/filter-dialog.component";
import { filter } from "rxjs";
@Component({
  selector: "app-luxe",
  templateUrl: "./luxe.component.html",
  styleUrls: ["./luxe.component.css"],
})
export class LuxeComponent implements OnInit {
  backgroundImageUrl: any;
  vila_filter: boolean = false;
  bannerText: any;
  loading: boolean = true;
  isChanged: boolean = false;
  imageSrc = "https://" + environment.domain + "/public/uploads/images/";
  imagePropertySrc =
    "https://" + environment.domain + "/public/property_images/";
  aboutData: any = [];
  locationData: any = [];
  categoryData: any = [];
  locationDatas: any = [];
  locationsNearbyData: any = [];
  optionss: any = [];
  headerData: any = [];
  propertyName = "";
  state = "";
  checkin: any;
  checkout: any;
  location = "";
  type = "";
  guest = "";
  party_friendly = "";
  budget_friendly = "";
  newly_villas = "";
  group_villas = "";
  offbeat_villas = "";
  featuredLocation: any;
  uniqueLocation = new Set<string>();

  guestoptions: string[] = [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
    "29",
    "30",
  ];
  minDate = new Date();
  selectedLocation: any;
  isBrowser: boolean;
  selectedGuest: any;

  constructor(
    public aboutservice: aboutservice,
    public locationservice: locationservice,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private metaService: Meta,
    private title: Title,
    private siteService: SiteDataHandlerService,
    public dialog: MatDialog,
    @Inject(PLATFORM_ID) platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(platformId);

    aboutservice
      .getLocationsNearby("Alibaug", "", "")
      .subscribe((response: any) => {
        this.locationsNearbyData = response["data"]["properties"];
      });

    let state = this.activatedRoute.snapshot.params.state;
    this.activatedRoute.queryParams.subscribe((params) => {
      this.state = params["state"] || "";
      var location = params["location"] || "";
      this.propertyName = params["propertyName"] || "";
      this.guest = params["guest"] || "";
      this.selectedGuest = params["guest"] || "";
      this.checkin = params["checkin"] || "";
      this.checkout = params["checkout"] || "";
      this.location = params["location"] || "";
      this.type = params["type"] || "";
      this.party_friendly = params["party_friendly"] || "";
      this.budget_friendly = params["budget_friendly"] || "";
      this.newly_villas = params["newly_villas"] || "";
      this.group_villas = params["group_villas"] || "";
      this.offbeat_villas = params["offbeat_villas"] || "";

console.log(this.guest,this.selectedGuest)

      //call method here based upon values to get filtered list.
      aboutservice.getLuxe(this.location,this.checkin,this.checkout,this.selectedGuest).subscribe((response: any) => {
        this.aboutData = response["data"]["properties"];
        this.initialFeaturedPropertyData = this.aboutData;
        this.maximize(this.aboutData[0]);
        this.aboutData.forEach((item: any) => {
          this.uniqueLocation.add(item.locname);
        });
        this.featuredLocation = Array.from(this.uniqueLocation);
      });

      aboutservice
        .getLocationsNearby(this.location, this.checkin, this.checkout)
        .subscribe((response: any) => {
          this.locationsNearbyData = response["data"]["properties"];
        });
    });

    this.title.setTitle("Ekostay - Luxe Category Villas");
    this.metaService.addTag({
      name: "description",
      content:
        "Luxe properties are a new offering by EKOSTAY designed for a luxurious and premium staycation",
    });

    locationservice.getLocations().subscribe((response: any) => {
      this.locationData = response["data"];
    });

    locationservice.getLocationsd().subscribe((response: any) => {
      this.locationDatas = response["data"];
    });

    aboutservice.getCategories().subscribe((response: any) => {
      this.categoryData = response["data"];
    });

    this.maximize(this.aboutData[0]);
  }

  customOptions: OwlOptions = {
    loop: false,
    autoplay: false,
    lazyLoad: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    lazyLoadEager: 2,
    navSpeed: 700,
    navText: [
      "<i class='fa fa-chevron-left'></i>",
      "<i class='fa fa-chevron-right'></i>",
    ],
    responsive: {
      0: {
        items: 1,
      },
    },
    nav: true,
  };

  luxevillas: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    navText: [
      "<i class='fa fa-chevron-left'></i>",
      "<i class='fa fa-chevron-right'></i>",
    ],
    responsive: {
      0: {
        items: 3,
      },
    },
    nav: true,
  };

  maximize(value: any) {
    this.isChanged = true;
    this.backgroundImageUrl = value;
    if (this.isBrowser) {
      setTimeout(() => {
        this.isChanged = false;
      }, 1300);
    }
  }

  cleanPropertyName(property_name: string) {
    return property_name?.replace(/EKOSTAY -|EKOSTAY-/g, "");
  }

  customOptionsAminity: OwlOptions = {
    loop: true,
    autoplay: true,
    lazyLoad: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    lazyLoadEager: 2,
    navSpeed: 700,
    responsive: {
      0: {
        items: 4,
      },
      400: {
        items: 5,
      },
      600: {
        items: 4,
      },
      1000: {
        items: 4,
      },
    },
    nav: false,
    dots: false,
  };

  ngOnInit(): void {
    if (this.isBrowser) {
      setTimeout(() => {
        this.loading = false;
      }, 4000);

      AOS.init();
      window.addEventListener("load", AOS.refresh);
    }

    this.siteService.checkIn.subscribe((res) => {
      if (res == undefined || res == null || res == "") {
        if (this.isBrowser) {
          this.checkin = sessionStorage.getItem("check-in");
        }
      } else {
        this.checkin = res;
      }
    });
    this.siteService.checkOut.subscribe((res) => {
      if (res == undefined || res == null || res == "") {
        if (this.isBrowser) {
          this.checkout = sessionStorage.getItem("check-out");
        }
      } else {
        this.checkout = res;
      }
    });

    this.aboutservice.goldLuxeDataBehaviour.subscribe((res: any) => {
      if (Object.keys(res).length > 0) {
        let temArr = [];
        if (res.minPrice && res.maxPrice) {
          temArr = this.initialFeaturedPropertyData
            .filter((item: any) => {
              const calculatedValue = item.inputname_monday * (1 + 0.26);
              return (
                res.minPrice <= calculatedValue &&
                calculatedValue <= res.maxPrice
              );
            })
            .map((filteredItem: any) => {
              return filteredItem;
            });
          this.aboutData = temArr;
        }
        if (
          res.ekoGold === true ||
          res.ekoLuxe === true ||
          res.ekoOriginal === true
        ) {
          temArr = this.initialFeaturedPropertyData.filter((item: any) => {
            if (res.ekoGold === true && res.ekoLuxe === true) {
              return item.gold === "on" || item.luxe === "on";
            }
            if (res.ekoGold === true && res.ekoOriginal === true) {
              return (
                item.gold === "on" || item.luxe !== "on" || item.gold !== "on"
              );
            }
            if (res.ekoOriginal === true && res.ekoLuxe === true) {
              return (
                (item.gold !== "on" && item.luxe === "on") ||
                (item.luxe !== "on" && item.gold !== "on")
              );
            }
            if (
              res.ekoOriginal === true &&
              res.ekoLuxe === true &&
              res.ekoGold == true
            ) {
              return (
                (item.gold === "on" && item.luxe === "on") ||
                (item.gold !== "on" && item.luxe === "on") ||
                (item.gold === "on" && item.luxe !== "on") ||
                (item.gold !== "on" && item.luxe !== "on")
              );
            }
            if (res.ekoGold === true) {
              return item.gold === "on";
            }
            if (res.ekoLuxe === true) {
              return item.luxe === "on";
            }
            if (res.ekoOriginal === true) {
              return item.gold !== "on" && item.luxe !== "on";
            }
            return false;
          });
        }
        this.aboutData = temArr;
        if (res.topFilterData) {
          temArr = this.initialFeaturedPropertyData.filter((item: any) => {
            if (res.bedroom && res.bathroom) {
              return (
                item.bedroom >= res.bedroom && item.bathroom >= res.bathroom
              );
            }
            if (res.bedroom) {
              return item.bedroom >= res.bedroom;
            }
            const arr = ["Chef Service", "Family Friendly"];
            if (res.bathroom) {
              return item.bathroom >= res.bathroom;
            }
            if (
              res.topFilterData.some(
                (filter: any) =>
                  filter.name === "Chef Service" ||
                  filter.name === "Family Friendly" ||
                  filter.name === "Budget Friendly" ||
                  filter.name === "Newly Launched" ||
                  filter.name === "Newly Launched" ||
                  filter.name === "Pet Friendly" ||
                  filter.name === "Best Rated" ||
                  (filter.name === "Offbeat" && filter.selected)
              )
            ) {
              return (
                item.family_friendly ||
                item.chef_avail ||
                item.budget_friendly ||
                item.offbeat_villas ||
                item.outdoorsy ||
                item.party_friendly ||
                item.pet_friendly ||
                item.newly_villas === "on"
              ); // Include items where "Chef Service" is selected
            }
            return false;
          });
        }
      } else {
        this.aboutData = this.initialFeaturedPropertyData;
      }
    });
  }

  
  selectedSort: any;
  sortBy: any = [
    { name: "Low to High", id: "low", arrange: "ase" },
    { name: "High to Low", id: "high" },
  ];
  initialFeaturedPropertyData: any;
  featuredPropertyData: any;
  numberOfNights: any;

  get isMobile() {
    return window.innerWidth > 768;
  }

  filterProperties() {
    if (
      !this.selectedLocation &&
      !this.selectedGuest &&
      !this.selectedSort &&
      !this.checkout
    ) {
      this.aboutData = this.initialFeaturedPropertyData;
    } else {
      if (this.selectedLocation) {
        this.aboutData = this.initialFeaturedPropertyData.filter(
          (item: any) => {
            // Filter based on both location and guest count
            return item.locname === this.selectedLocation;
          }
        );
      } else if (this.selectedGuest) {
        this.aboutData = this.initialFeaturedPropertyData.filter(
          (item: any) => {
            return item.member >= this.selectedGuest;
          }
        );
      } else if (this.selectedSort) {
        if (this.selectedSort === "low") {
          // Sort by price in ascending order
          this.aboutData = this.initialFeaturedPropertyData.sort(
            (a: any, b: any) => {
              return (
                a.inputname_monday * (1 + 0.26) -
                b.inputname_monday * (1 + 0.26)
              );
            }
          );
        } else if (this.selectedSort === "high") {
          // Sort by price in descending order
          this.aboutData = this.initialFeaturedPropertyData.sort(
            (a: any, b: any) => {
              return (
                b.inputname_monday * (1 + 0.26) -
                a.inputname_monday * (1 + 0.26)
              );
            }
          );
        }
      }
    }
    var dateN = new Date(this.checkin);
    var dateM = new Date(this.checkout);

    if (this.checkin != "" && this.checkout != "") {
      var timeDiff = dateM.getTime() - dateN.getTime();
      this.numberOfNights = timeDiff / (1000 * 3600 * 24);
    } else {
      this.numberOfNights = 0;
    }
  }

  onUnSelectedLocation(event: any, name: string) {
    if (name === this.selectedLocation) {
      this.selectedLocation = null;
      this.filterProperties();
      // event.stopPropagation();
    }
  }

  showVilla() {
    this.vila_filter = true;
    this.scrollWins();
  }

  scrollWins() {
    if (this.isBrowser) {
      window.scrollTo({
        top: 1650,
        behavior: "smooth",
      });
    }
  }

  filterpropertiess() {}

  checkIn(value: any) {
    var date1 = moment(this.checkin).format("YYYY-MM-DD");
    this.checkin = date1;
    this.checkDateValidity();
  }

  checkOut(value: any) {
    var date2 = moment(this.checkout).format("YYYY-MM-DD");
    this.checkout = date2;
  }

  errorMessage: string = "";
  checkDateValidity(): void {
    if (this.checkin && this.checkout) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      if (
        this.checkout < this.checkin ||
        this.checkin < today ||
        this.checkout < today
      ) {
        this.errorMessage =
          "Invalid date range. Please select valid check-in and check-out dates.";
      } else {
        this.errorMessage = "";
      }
    }
  }

  openDialog(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = "100%"; // Set the desired width
    dialogConfig.maxWidth = "450px";
    dialogConfig.height = "100vh";
    dialogConfig.panelClass = "filter_modal";
    const dialogRef = this.dialog.open(GoldLuxeDialogComponent, dialogConfig);
  }

  openFilterDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = "filter_modal";
    dialogConfig.data = this.locationDatas;
    const dialogRef = this.dialog.open(FilterDialogComponent, dialogConfig);

    dialogRef
      .afterClosed()
      .pipe(filter((data) => data))
      .subscribe((data) => {
        this.checkin = data.checkIn;
        this.checkout = data.checkOut;
        this.selectedGuest = data.guest;
        this.filterProperties();
        if (this.selectedGuest) {
          this.aboutData = this.initialFeaturedPropertyData.filter(
            (item: any) => {
              return item.member >= this.selectedGuest;
            }
          );
        }
        var dateN = new Date(this.checkin);
        var dateM = new Date(this.checkout);

        if (this.checkin != "" && this.checkout != "") {
          var timeDiff = dateM.getTime() - dateN.getTime();
          this.numberOfNights = timeDiff / (1000 * 3600 * 24);
        } else {
          this.numberOfNights = 0;
        }
      });
  }

  selectAll() {
    // Handle "Select All" logic here if needed
    // For now, let's clear the selection
    this.clearSelection();
  }
  clearSelection() {
    this.selectedLocation = undefined;
    this.filterProperties();
  }
  selectLocation(location: string) {
    this.selectedLocation = location;
    this.filterProperties();
  }
  ngAfterViewInit() {
    this.filterProperties();
  }
  openPropertyVideo() {
    const videoUrl = this.backgroundImageUrl.video_prismic_url;

    if (videoUrl) {
      if (this.isBrowser) {
        // Open the video URL in a new tab or window
        window.open(videoUrl, "_blank");
      }
    }
  }
}
