import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatAutocomplete, MatAutocompleteTrigger } from "@angular/material/autocomplete";
import { Observable, map, startWith } from "rxjs";
import { FormControl, UntypedFormControl } from "@angular/forms";

export interface LocationData {
  name: string;
  image: string;
}

@Component({
    selector: "selectOption-dialog",
    templateUrl: "selectoption-dialog.html",
    styleUrls: ["./selectoption-dialog.css"],
  })
  export class selectOptionDialog implements OnInit {

    @ViewChild('firstBox') firstBox: MatAutocomplete
  
    autoCompleteOpen(){
      setTimeout(()=> {
        this.firstBox.panel.nativeElement?.classList?.add('panelCustomClass');
        this.firstBox.panel.nativeElement.parentElement.classList.add('parentClass')
        // this.setPanelPosition()
      }, 100)
    }


    locationData:LocationData[]=[]
    locationDataInit:any
    filteredOptions: Observable<LocationData[]>;
    location_property:any
    constructor(public dialogRef: MatDialogRef<selectOptionDialog>,@Inject(MAT_DIALOG_DATA) public data: any) {
      console.log(data,"176")
      this.locationDataInit = data
      this.locationData = this.locationDataInit.locationData
      this.location_property = this.locationDataInit.location_property;
      this.myControl.setValue({name:data.location_property});
    }

    myControl = new FormControl<any>({});
    ngOnInit(){
      this.filteredOptions = this.myControl.valueChanges.pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value?.name),
        map(name => name ? this._filter(name) : this.locationData.slice())
      );
      console.log(this.filteredOptions,"32")
    }

    
    displayFn(locationData: LocationData): string {
      return locationData && locationData.name ? locationData.name : '';
    }
  
    private _filter(name: string): LocationData[] {
      const filterValue = name.toLowerCase();
      return this.locationData.filter(option => option.name.toLowerCase().includes(filterValue));
    }

    onLocationChange(value: any) {
      console.log('Selected Location:', value.name,"61");
      // You can perform additional operations here if needed
      if (this.locationData.some(location => location.name === value.name)) {
        console.log('Selected Location:', value.name);
        this.dialogRef.close(value.name);
    }
    }

    closeDialog(): void {
      this.dialogRef.close();
    }
    
  }