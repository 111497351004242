import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DataLayerService {
  constructor() {
    // Constructor logic if needed
  }

  pushToDataLayer(data: any) {
    (<any>window).dataLayer = (<any>window).dataLayer || [];
    (<any>window).dataLayer.push(data);
  }

  trackEvent(event: string, data: any): void {
    (<any>window).dataLayer.push({ event, ...data });
  }
}
