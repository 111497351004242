<ng-container>
  <div
    class="col-md-6 bsp_padding-0 mobile-des"
    data-aos="fade-up"
    data-aos-duration="1000"
  >
    <div
      class="bsp_bbb_item"
      [ngClass]="{
        'gold-bg': property1.gold == 'on',
        'luxe-bg': property1.luxe == 'on'
      }"
    >
      <img
        *ngIf="property1.gold == 'on'"
        class="ribbon"
        src="assets/img/black-ribbon.png"
      />
      <div *ngIf="property1.gold == 'on'" class="ribbon-word">Eko Gold</div>
      <img
        *ngIf="property1.luxe == 'on'"
        class="ribbon"
        src="assets/img/black-ribbon.png"
      />
      <div *ngIf="property1.luxe == 'on'" class="ribbon-word eko_luxe">
        Eko Luxe
      </div>
      <div class="image-box">
        <owl-carousel-o
          [options]="customOptions"
          *ngIf="property1?.property_media?.length != 0"
        >
          <ng-container
            *ngFor="
              let pmedia of property1.property_media | SortBy : 'sort';
              index as i
            "
          >
            <ng-template carouselSlide [id]="pmedia.id">
              <div class="slide">
                <a (click)="navigationPage(property1)" class="bsp_a">
                  <img
                    [src]="imageSrc ? imageSrc + pmedia.media : pmedia.media"
                    width="300"
                    height="300px"
                    alt=""
                  />
                </a>
                <div *ngIf="i === 4" class="view-more-slide">
                  <a (click)="navigationPage(property1)" target="_blank">
                    <div class="view-more-button-slide">View More</div>
                  </a>
                </div>
              </div>
            </ng-template>
          </ng-container>
        </owl-carousel-o>
        <div class="slide">
          <a
            (click)="navigationPage(property1)"
            loading="lazy"
            class="bsp_a"
            target="_blank"
          >
            <img
              ngSrc="{{ property1.image }}"
              width="358"
              height="200"
              alt="Villa in {{ location }}"
            />
          </a>
        </div>
      </div>

      <a (click)="navigationPage(property1)" class="bsp_a"> </a>
    </div>
    <div class="villa_info">
      <div
        class="villa_name"
        style="display: flex; justify-content: space-between"
      >
        <div>
          <p class="Text-title" (click)="navigationPage(property1)">
            {{ cleanPropertyName(property1.property_name) }}
          </p>
          <p class="Text-subtitle">
            {{ property1.locname }}
          </p>
        </div>
        <!-- <div class="villa_price">
            ₹

            {{
              numberOfNightss
                ? (property1.inputname_monday *
                    (1 + 0.26) *
                    numberOfNightss | number : "2.")
                : (property1.inputname_monday * (1 + 0.26)
                  | number : "2.")
            }}
            <span class="Onwards">Night Onwards</span>
          </div> -->
        <div class="villa_price" *ngIf="numberOfNightss === 0">
          <ng-container *ngIf="property1.status != 0">
            ₹
            <span class="line-price">{{
              numberOfNightss
                ? (property1.inputname_monday * (1 + 0.26) * numberOfNightss
                  | number : "2.")
                : (property1.inputname_monday * (1 + 0.26) | number : "2.")
            }}</span>
            {{ property1.inputname_monday | number : "2." }}/-
          </ng-container>
          <span class="Onwards" *ngIf="property1.status != 0"
            >Per Night Onwards</span
          >
          <h5 class="bsp_card-title" *ngIf="property1.status == 0">
            <span
              style="
                color: black;
                font-family: shippori-mincho-rehular, serif;
                font-weight: 600;
                font-style: normal;
              "
              >Currently Inactive</span
            >
          </h5>
        </div>
        <div
          class="villa_price"
          *ngIf="
            numberOfNightss != 0 &&
            (property1.final_price_with_extraa_guest ||
              property1.final_price_pi)
          "
        >
          ₹
          {{
            property1.final_price_with_extraa_guest || property1.final_price_pi
          }}/-
          <span class="Onwards" *ngIf="numberOfNightss === 1"
            >for {{ numberOfNightss }} Night</span
          >
          <span class="Onwards" *ngIf="numberOfNightss > 1"
            >for {{ numberOfNightss }} Nights</span
          >
        </div>
        <div
          class="villa_price"
          *ngIf="
            numberOfNightss != 0 &&
            !property1.final_price_with_extraa_guest &&
            !property1.final_price_pi
          "
        >
          <span class="sold-out">Sold Out</span>
        </div>
      </div>
      <!-- ---------------------------------------------------- -->
      <div class="detail-desc">
        <div class="text-left">
          <ul class="roominn">
            <li class="third-desc">
              <i aria-hidden="true" class="fa fa-users"></i>
              <span class="icon-count">{{ property1.member }}&nbsp;Guest</span>
            </li>

            <li class="first-desc">
              <i aria-hidden="true" class="fa fa-bed"></i
              ><span class="icon-count">{{ property1.bed }}&nbsp;BHK</span>
            </li>
            <li class="second-desc">
              <i class="fa fa-bath" aria-hidden="true"></i
              ><span class="icon-count"
                >{{ property1.bathrooms }}&nbsp;Bath</span
              >
            </li>
          </ul>
        </div>
      </div>

      <div class="detail-desc-1" *ngIf="property1?.amenitiesDetail?.length < 3">
        <div class="text-left mb-3">
          <ul
            class="roominn"
            style="list-style-type: none; padding: 0; margin: 0"
          >
            <li
              class="aminity-list"
              *ngFor="let amenity of property1?.amenitiesDetail"
            >
              <img
                [src]="imageSrc1 + amenity.image"
                alt=""
                class="aminiy-img"
              />
              <span class="icon-count">{{ amenity.name }}</span>
            </li>
          </ul>
        </div>
      </div>

      <!-- *ngIf="isMobile" -->
      <div
        class="story-carousels"
        *ngIf="property1?.amenitiesDetail?.length > 3"
      >
        <owl-carousel-o [options]="customOptionsAminity" class="dots-hide">
          <ng-container
            *ngFor="let item of property1.amenitiesDetail; index as i"
          >
            <!-- <ng-container *ngIf="item.status != 0 && item.video_prismic_url != null"> -->
            <ng-template carouselSlide [id]="item.id">
              <div class="slide">
                <div style="position: relative; text-align: -webkit-center">
                  <!-- <video #videoPlayer width="100" height="100" (click)="playVideo(item.video_prismic_url, item)"  class="villa-image" muted playsinline>
                        <source [src]="item.video_prismic_url" type="video/mp4">
                        Your browser does not support the video tag.
                      </video> -->
                  <img
                    [src]="imageSrc1 + item.image"
                    class="aminiy-img"
                    style="top: 0; left: 0"
                  />
                </div>
                <span class="icon-count">{{ item.name }}</span>
              </div>
            </ng-template>
            <!-- </ng-container> -->
            <div class="slide">
              <img
                src="https://dfstudio-d420.kxcdn.com/wordpress/wp-content/uploads/2019/06/digital_camera_photo-1080x675.jpg"
                width="100"
                height="100px"
                alt=""
              />
            </div>
          </ng-container>
        </owl-carousel-o>
      </div>

      <div class="badges">
        <span class="badge badge alert-lux" *ngIf="property1.all_white == 'on'"
          >All White</span
        >

        <span class="badge badge alert-lux" *ngIf="property1.outdoorsy == 'on'"
          >Outdoorsy</span
        >
        <span class="badge badge alert-lux" *ngIf="property1.scenic == 'on'"
          >Scenic</span
        >
        <span
          class="badge badge alert-lux"
          *ngIf="property1.mega_structure == 'on'"
          >Mega Structure</span
        >
        <span
          class="badge badge alert-lux"
          *ngIf="property1.youth_friendly == 'on'"
          >Insta Worthy</span
        >
      </div>
    </div>
  </div>
</ng-container>
