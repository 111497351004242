import { Component, Inject, OnInit, PLATFORM_ID } from "@angular/core";
import { Observable, fromEvent } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { Title } from "@angular/platform-browser";
import { detailservice } from "../../services/detailservice";
import { environment } from "src/environments/environment";
import { takeUntil } from "rxjs/operators";
import { OwlOptions } from "ngx-owl-carousel-o";
import * as AOS from "aos";
import { isPlatformBrowser } from "@angular/common";
import { DataLayerService } from "src/app/services/data-layer.service";
import CryptoJS from 'crypto-js';
declare var wigzo: any;

@Component({
  selector: "app-thank-you",
  templateUrl: "./thank-you.component.html",
  styleUrls: ["./thank-you.component.css"],
})
export class ThankYouComponent implements OnInit {
  unsubscriber: Observable<any>;
  location: any = [];
  imageSrc = "https://" + environment.domain + "/public/uploads/images/";
  email: any = [];
  phoneno: any = [];
  fname: any = [];
  lname: any = [];
  checkin: any = [];
  checkout: any = [];
  guest: any = [];
  price: any = [];
  detailDatas: any = [];
  detailDatass: any = [];
  hidebook: boolean = false;
  imagePropertySrc =
    "https://" + environment.domain + "/public/property_images/";

  constructor(
    private title: Title,
    private activatedRoute: ActivatedRoute,
    public detailservice: detailservice,
    public router:Router,
    @Inject(PLATFORM_ID) platformId: Object,
    private dataLayerService: DataLayerService,
  ) {

    this.isBrowser = isPlatformBrowser(platformId);
    this.hidebook = true;
  }

  customOptions: OwlOptions = {
    loop: true,
    lazyLoad: true,
    autoWidth: true,
    dots: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    lazyLoadEager: 2,
    navSpeed: 700,
    navText: [
      "<i class='fa fa-chevron-left'></i>",
      "<i class='fa fa-chevron-right'></i>",
    ],
    responsive: {
      0: {
        items: 1,
      },
    },
    nav: true,
  };

  propertyName: any;
  propertyMedia: any;
  showloader:boolean=true
  isBrowser:boolean;
  bookingId:any;
  propertyImage:any


  ngOnInit(): void {
  if(this.isBrowser){

    AOS.init();
    window.addEventListener("load", AOS.refresh);
  

    this.title.setTitle("Thank You");

    this.activatedRoute.queryParams.subscribe((queryParams) => {
      this.location = this.activatedRoute.snapshot.params.locationname;
      this.email = atob(
        this.activatedRoute.snapshot.queryParams.U9ylOtZa6KdYfAmko1E
      );
      this.phoneno = atob(this.activatedRoute.snapshot.queryParams.phoneno);
      this.fname = atob(this.activatedRoute.snapshot.queryParams.name);
      this.lname = atob(this.activatedRoute.snapshot.queryParams.lastname);
      this.checkin = atob(this.activatedRoute.snapshot.queryParams.checkin);
      this.checkout = atob(this.activatedRoute.snapshot.queryParams.checkout);
      this.guest = atob(this.activatedRoute.snapshot.queryParams.guest);
      this.price = atob(this.activatedRoute.snapshot.queryParams.price);
      this.bookingId = atob(this.activatedRoute.snapshot.queryParams.bookingId)
    });

    wigzo ("track", "order",{orderId: this.bookingId, email: this.email, phone:
      this.phoneno, fullName: this.fname, total_line_items_price: this.price, customer_id: Number(this.phoneno)})

    const hashedEmail = CryptoJS.SHA256(this.email).toString();
    const hashedPhoneNumber = CryptoJS.SHA256(this.phoneno).toString();

    fbq("track", "Purchase", {
      "data": [
        {
          "event_name": "Purchase",
          "event_time": Math.floor(Date.now() / 1000),
          "event_source_url": 'ekostay.com' + this.router.url,
          "action_source": "website",
          "user_data": {
            "em": [hashedEmail],
            "ph": [hashedPhoneNumber]
          },
          "custom_data": {
            "currency": "INR",
            "value": this.price
          }
        }
      ]
    });
    
    this.detailservice.trackPurchase(this.email, this.phoneno, this.price).subscribe(
      () => {
        console.log('Purchase event tracked successfully.');
      },
      error => {
        console.error('Error tracking purchase event:', error);
      }
    );
  
    
    // const datas = {
    //   event: "booking_Confirm",
    //     purchase: {
    //       actionField: {
    //         id: this.bookingId,
    //         affiliation: 'Online',
    //       },
    //       products: [
    //         {
    //           name: this.propertyName, 
    //           location: this.detailDatas[0]?.locname,
    //           price: this.price.toString(),
    //           checkin: this.checkin,
    //           checkout: this.checkout,
    //         }
    //       ]
    //   }
    // };
    
    // this.dataLayerService.pushToDataLayer( datas );


    let id = this.activatedRoute.snapshot.params.id;
    this.detailservice.getDetails(id).subscribe((response: any) => {
      if(response){
        wigzo("track", "buy", [response.data.property[0].pid]) 
      }
      this.detailDatas = response["data"]["property"];
      this.detailDatass = response["data"]["images"];
      this.propertyMedia = this.detailDatas[0].property_media;
      this.propertyImage =response["data"]["images"]
      this.propertyName = this.detailDatas[0].property_name;
      this.propertyName = this.propertyName.replace("EKOSTAY -", "");
      this.hidebook = false;
    });
if(this.isBrowser){

  history.pushState(null, "");

  fromEvent(window, "popstate")
    .pipe(takeUntil(this.unsubscriber))
    .subscribe((_) => {
      history.pushState(null, "");
      this.showErrorModal(`You can't make changes or go back at this time.`);
    });
}
  }
  }
  showErrorModal(arg0: string) {
    throw new Error("Method not implemented.");
  }
}

declare var fbq: Function;
