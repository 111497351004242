import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, SimpleChange, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { aboutservice } from 'src/app/services/aboutservice';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-property-card',
  templateUrl: './property-card.component.html',
  styleUrls: ['./property-card.component.css'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class PropertyCardComponent implements OnInit {

  imageSrc1 = "https://" + environment.domain + "/public/uploads/images/";
  @Input() property1 :any
  @Input() customOptions:any
  @Input() location:any
  @Input() numberOfNightss:any
  @Input() customOptionsAminity:any
  @Input() imageSrc:any;
  @Input() final_price_pi:any
  // imagePropertySrc =
  // "https://" + environment.domain + "/public/property_images/";

  constructor(private router:Router, private aboutService:aboutservice, private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    console.log(this.numberOfNightss,"25")

  }

  ngOnChanges(changes: SimpleChanges) {
    if ('numberOfNightss' in changes) {
      console.log(this.numberOfNightss, this.final_price_pi);
      this.cdr.detectChanges(); // Manually trigger change detection
    }
  }

  cleanPropertyName(property_name: string): string {
    return property_name?.replace(/EKOSTAY -|EKOSTAY-/g, "");
  }
  navigationPage(property1:any){
console.log(property1,"35")
this.router.navigateByUrl('/property/location/villas-in-' + property1.locname + '/' + property1.permalink);


  }

}
