import { Component, OnInit } from '@angular/core';
import { blogservice } from '../../services/blogservice';
import { environment } from '../../../environments/environment';
import { Title,Meta, MetaDefinition } from '@angular/platform-browser';
@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.css']
})
export class BlogComponent implements OnInit {

  imageSrc = 'https://'+environment.domain+'/public/uploads/images/';
  blogData: any = [];

  constructor(public blogservice: blogservice,private metaService: Meta,private title: Title) {
    blogservice.getBlogs().subscribe((response:any) =>
      {
       this.blogData = response['data'];
       this.blogData = this.blogData.sort((a: any, b: any) => new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime());
       console.log(this.blogData,"20")
      });

      this.title.setTitle('Ekostay - Press and Media');
     this.metaService.addTag({ name: 'description', content: 'We at EKOSTAY work with media and journalists to share useful insights of the alternative accommodation space in India' });
   }

  ngOnInit(): void {
  }
}
